<template>
  <div class="passenger">
    <div class="passenger_box w-1200 margin-auto" style="padding-bottom: 30px;">
      <div class="passenger_top flex p-t-20 p-b-20">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/passenger?type=1' }"
            >客源管理
          </el-breadcrumb-item>
          <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="passenger_top_time">上架时间：{{ details.createtime }}</div>
      </div>
      <div class="passenger_head">
        <div class="passenger_head_top flex">
          <div class="passenger_head_left flexs">
            <div class="passenger_head_guest">{{ details.client_type == 1 ? '私' : '公' }}客</div>
            <div class="passenger_head_name">{{ details.name }}</div>
            <div class="passenger_head_sex">{{ details.sex }}</div>
            <div class="passenger_head_type">A类</div>
            <div class="passenger_head_finish">
              {{ details.purpose_house_type == 10000 ? '标间' : details.purpose_house_type}}{{ details.purpose_house_type == 10000 ? '' : '居'}}
            </div>
            <div
              class="passenger_head_roll center"
              @click="joinBlacklist"
              v-if="details.is_black == 1"
            >
              <img src="../../assets/image/houseing/defriend.png" alt="" />
              <!-- <span>{{ details.is_black == 1 ? "加入" : "移除" }}黑名单</span> -->
            </div>
          </div>
          <div class="passenger_head_price flexs">
            <div class="passenger_head_price_l flexs m-r-30">
              <span>预算</span>
              <span
                >{{ details.min_total_price }}-{{
                  details.max_total_price
                }}元</span
              >
            </div>
            <el-button>{{ getStatus(details.status) }}</el-button>
          </div>
        </div>
        <div class="fl m-t-30 m-b-60">
          <ul class="passenger_head_ul">
            <li>
            <span>客户贵姓：</span>
            <span>{{ details.kehu_xing }}</span>
            </li>
            <li>
              <span>锁定小区：</span>
              <span>{{ details.purpose_little_area }}</span>
            </li>
            <li>
              <span>第一需求：</span>
              <span>{{ details.xuqiu_yi }}</span>
            </li>
            <li>
              <span>最高价格：</span>
              <span>{{ details.gao_prc }}</span>
            </li>
            <li>
              <span>现住到期：</span>
              <span>{{ details.x_daoqi }}</span>
            </li>
            <li>
              <span>入住时间：</span>
              <span>{{ details.fastest_time }}</span>
            </li>
            
            <li>
              <span>办公地点：</span>
              <span>{{ details.s_xiaoqu }}</span>
            </li>
            <li>
              <span>锁定学区：</span>
              <span>{{ details.s_xuequ }}</span>
            </li>
          </ul>
          <ul class="passenger_head_ul">
            <li>
              <span>租赁时长：</span>
              <span>{{ details.rent_time }}</span>
            </li>
            <li>
              <span>意向朝向：</span>
              <span>{{ details.orientation }}</span>
            </li>
            <li>
              <span>意向房型：</span>
              <span>{{ details.purpose_house_type }}</span>
            </li>
            <li>
              <span>意向面积：</span>
              <span
                >{{ details.min_purpose_area }}-{{
                  details.max_purpose_area
                }}㎡</span
              >
            </li>
            <li>
              <span>付款方式：</span>
              <span>{{ details.pay_way }}</span>
            </li>
            <li>
              <span>租住方式：</span>
              <span>{{ details.rent_way }}</span>
            </li>
            <li>
              <span>是否需要车位：</span>
              <span>{{ details.is_carport == 1 ? "否" : "是" }}</span>
            </li>
            <li>
              <span>近10天带看次数：</span>
              <span>{{ details.look_num }}</span>
            </li>
          </ul>
          <ul class="passenger_head_ul">
            <li>
              <span>经纪人：</span>
              <span>{{ details.broker_name }}</span>
            </li>
            <li>
              <span>委托店铺：</span>
              <span>{{ details.dian_name }}</span>
            </li>
            <li>
              <span>意向装修：</span>
              <span>{{ details.finish_type }}</span>
            </li>
            
            <li>
              <span>客户来源：</span>
              <span>{{ details.client_source }}</span>
            </li>
            <li>
              <span>房源类型：</span>
              <span>{{ getHouseType(details.house_type) }}</span>
            </li>
            <li>
              <span>最近带看日期：</span>
              <span>{{ details.look_date }}</span>
            </li>
            <li>
              <span>最近跟进日期：</span>
              <span>{{ details.follow_date }}</span>
            </li>
            <li>
              <span>补充标签：</span>
              <span>{{ details.client_label }}</span>
            </li>
          </ul>
        </div>
        <div class="passenger_head_btn center">
          <el-button @click="lookPhone"
            >查看联系方式(今日{{ details.look_number }}次)</el-button
          >
        </div>
      </div>
      <ul class="passenger_nav flex p-t-30 p-b-30">
        <li
          v-for="(item, index) in navList"
          :key="index"
          @click="changeNav(index)"
        >
          <img :src="item.img" alt="" />
          <span>{{ item.name }}</span>
        </li>
      </ul>
      <div class="passenger_footer fl">
        <div class="passenger_footer_left">
          <ul class="passenger_footer_head flexs">
            <li
              @click="changeHead(index)"
              :class="{ active: index == headIndex }"
              v-for="(item, index) in 2"
              :key="index"
            >
              {{
                index == 0
                  ? "约看记录"
                  : "带看记录"
              }}
            </li>
          </ul>
          <template>
            <ul class="passenger_footer_list1">
              <li v-for="(item, index) in list" :key="index">
                <div class="passenger_footer_list1_ul">
                  <div class="passenger_footer_list1_ul_li">
                    <span>{{ headIndex == 1 ? "带" : "约" }}看时间：</span>
                    <span>{{ item.date_time }}</span>
                  </div>
                  <!-- <div class="passenger_footer_list1_ul_li">
                    <span>{{ headIndex == 2 ? '带' : '约' }}看客户：</span>
                    <span>{{ item.rec_name }}</span>
                  </div> -->
                  <div class="passenger_footer_list1_ul_lis passenger_footer_list1_ul_li">
                    <div :key="ind" v-for="(em, ind) in item.content">
                      <div
                        class="f-1 flexs"
                        style="width: 100%;"
                      >
                        <div
                          class="
                            passenger_footer_list1_ul_li
                            passenger_footer_list1_ul_lis
                          "
                        >
                          <span>房源信息{{ ind + 1 }}：</span>
                          <span>{{ em.village_name }}{{ em.house_info }}</span>
                        </div>
                        <!-- <div
                          class="
                            passenger_footer_list1_ul_li
                            passenger_footer_list1_ul_lis
                          "
                        >
                          <span>小区地址：</span>
                          <span>{{ em.village_address }}</span>
                        </div> -->
                      </div>
                    </div>
                  </div>

                  <div class="passenger_footer_list1_ul_li">
                    <span>{{ headIndex == 1 ? "带" : "约" }}看反馈：</span>
                    <span>{{ item.remark }}</span>
                  </div>
                </div>
              </li>
            </ul>
          </template>
        </div>
        <div class="">
          <div class="passenger_footer_right">
            <div class="passenger_footer_right_head"><span>跟进记录</span></div>
            <ul class="passenger_footer_right_ul">
              <li v-for="(item, index) in followList" :key="index">
                <div class="passenger_footer_right_ul_top flex">
                  <div class="passenger_footer_right_ul_left flexs">
                    <div class="passenger_footer_right_ul_img m-r-10">
                      <img :src="item.b_image" alt="" />
                    </div>
                    <div class="passenger_footer_right_ul_con">
                      <div class="passenger_footer_right_ul_name">
                        {{ item.b_name }}
                      </div>
                      <div class="passenger_footer_right_ul_time m-t-10">
                        {{ item.createtime }}
                      </div>
                    </div>
                  </div>
                  <div class="passenger_footer_right_ul_right"></div>
                </div>
                <div class="passenger_footer_right_ul_txt m-t-10">
                  {{ item.remark }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <change-modify ref="modify" @succeed="changeModify"></change-modify>
    <others-passenger ref="others"></others-passenger>
    <!-- 变更客源 S -->
    <change-passenger ref="alteration" @succeed="succeed"></change-passenger>
    <!-- 变更客源 E -->
    <!-- 删除客源 S -->
    <del-passenger ref="delpassenger" @succeed="succeed"></del-passenger>
    <!-- 删除客源 E -->
    <!-- 我要跟进 S -->
    <follow ref="follow" @succeed="succeed"></follow>
    <!-- 我要跟进 E -->
    <!-- 带看反馈 S -->
    <band-feedback ref="band" @succeed="succeed"></band-feedback>
    <!-- 带看反馈 E -->

    <!-- 查看联系方式 S -->
    <build-contact ref="lookPhone" @succeed="succeed" @close="close"></build-contact>
    <!-- 查看联系方式 E -->
  </div>
</template>

<script>
import BandFeedback from "../../components/BandFeedback.vue";
import BuildContact from "../../components/BuildContact.vue";
import ChangePassenger from "../../components/ChangePassenger.vue";
import ChangeModify from "../../components/ChangeModify.vue";
import DelPassenger from "../../components/DelPassenger.vue";
import follow from "../../components/Follow.vue";
import OthersPassenger from '../../components/OthersPassenger.vue'
export default {
  components: {
    ChangePassenger,
    OthersPassenger,
    ChangeModify,
    DelPassenger,
    follow,
    BandFeedback,
    BuildContact,
  },
  data() {
    return {
      id: null, //客源id
      type: 1, //
      details: {}, //详情
      navList: [
        {
          img: require("../../assets/image/passenger/01.png"),
          name: "编辑",
          type: 1,
        },
        {
          img: require("../../assets/image/passenger/02.png"),
          name: "变更客源",
          type: 1,
        },
        {
          img: require("../../assets/image/passenger/03.png"),
          name: "删除",
          type: 1,
        },
        // {
        //   img: require("../../assets/image/passenger/04.png"),
        //   name: "我要跟进",
        //   type: 1,
        // },
        {
          img: require("../../assets/image/passenger/05.png"),
          name: "带看反馈",
          type: 1,
        },
        {
          img: require("../../assets/image/passenger/06.png"),
          name: "约看反馈",
          type: 1,
        },
      ],
      headIndex: 0, //
      agreementList: [], //越看记录
      brokerList: [], //经纪人记录
      bandList: [], //带看记录
      followList: [], //跟进记录
      list: [], //
    };
  },
  methods: {
    changeModify (index) {
      this.$refs[index == 0 ? 'alteration' : 'others'].open(this.id, this.details.client_type);
    },
    close (err) {
      console.log(err);
    },
    //跟进
    follow() {
      this.getlookLog(7);
    },
    //查看联系方式
    lookPhone() {
      this.$refs.lookPhone.open(this.id, 2, 0, 0);
    },
    //切换
    changeHead(index) {
      this.headIndex = index;
      let txt = index == 0 ? 1 : 5;
      this.getlookLog(txt);
    },
    //变更
    changeNav(index) {
      switch (index) {
        case 0:
          this.$router.push({
            path: "/editPassenger",
            query: { id: this.id, type: this.type },
          });
          break;
        case 1:
          this.$refs.modify.open(this.details.client_type);
          break;
        case 2:
          this.$refs.delpassenger.open(this.id);
          break;
        case 3:
          // this.$refs.follow.open(this.id, this.details.look_id);
          this.$refs.band.open(this.id, 1);
          break;
        case 4:
          // this.$refs.band.open(this.id, 1);
          this.$refs.band.open(this.id, 2);
          break;
        case 5:
          this.$refs.band.open(this.id, 2);
          break;
        default:
          break;
      }
    },
    succeed() {
      this.getDetails();
      this.getlookLog(1);
      this.getlookLog(7);
    },
    //加入黑名单
    joinBlacklist() {
      if (this.details.is_black == 2) return;
      this.$confirm("是否加入黑名单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .joinBlack({
              id: this.id,
              type: this.details.type == 1 ? 3 : 4,
            })
            .then((res) => {
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.getDetails();
            });
        })
        .catch(() => {});
    },
    //获取详情
    getDetails() {
      this.$axios.clientDetail({ id: this.id }).then((res) => {
        this.details = res.data;
      });
    },
    // 1 约看记录；3 约看经纪人记录；5带看记录；7跟进记录
    getlookLog(type) {
      this.$axios
        .lookLog({
          type: type,
          page: 1,
          ref_id: this.id,
        })
        .then((res) => {
          if (type == 7) {
            this.followList = res.data.data;
          } else {
            this.list = res.data.data;
          }
        });
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    this.getlookLog(1);
    this.getlookLog(7);
    this.getDetails();
  },
};
</script>

<style lang="less" scoped>
.passenger {
  background: #ffffff;
}
.passenger_top_time {
  color: #666666;
  font-size: 18px;
}
.passenger_head {
  padding: 30px 30px 40px 30px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
}
.passenger_head_ul {
  width: 33.3%;
  li {
    
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
    span {
      font-size: 16px;
      &:first-child {
        color: #999999;
      }
    }
  }
}
.passenger_head_left {
  .passenger_head_name {
    font-size: 18px;
  }
  .passenger_head_guest {
    font-size: 20px;
    color: #ff2121;
    margin-right: 20px;
  }
  .passenger_head_sex {
    margin: 0 15px 0 20px;
    color: #666666;
    font-size: 16px;
  }
  .passenger_head_type {
    color: #3273f6;
    font-size: 14px;
    text-align: center;
    height: 28px;
    padding: 0 10px;
    line-height: 28px;
    background: #d5e3ff;
    border-radius: 3px;
  }
  .passenger_head_finish {
    color: #ff752a;
    font-size: 14px;
    margin: 0 10px;
    height: 28px;
    line-height: 28px;
    padding: 0 10px;
    text-align: center;
    background: rgba(255, 117, 42, 0.1);
    border-radius: 3px;
  }
  .passenger_head_roll {
    height: 28px;
    padding: 0 10px;
    cursor: pointer;
    // border: 1px solid #ff2121;
    border-radius: 3px;
    img {
      width: 16px;
      height: 16px;
      // margin-right: 8px;
    }
    span {
      color: #ff2121;
      font-size: 14px;
    }
  }
}
.passenger_head_price {
  .passenger_head_price_l {
    span {
      color: #666666;
      font-size: 18px;
      &:last-child {
        color: #ff2121;
        font-size: 20px;
      }
    }
  }
  /deep/ .el-button {
    padding: 0 10px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    background: linear-gradient(43deg, #3273f6, #689aff);
    border-radius: 3px;
    span {
      color: #ffffff;
      font-size: 14px;
    }
  }
}
.passenger_head_btn {
  /deep/ .el-button {
    width: 360px;
    height: 48px;
    color: #ffffff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 4px;
  }
}
.passenger_nav {
  li {
    width: 170px;
    height: 56px;
    display: flex;
    cursor: pointer;
    padding-left: 20px;
    align-items: center;
    background: #e1ebff;
    border-radius: 4px;
    img {
      width: 36px;
      height: 36px;
      margin-right: 10px;
    }
    span {
      font-size: 16px;
    }
    &:nth-child(2) {
      background: #ffeebc;
    }
    &:nth-child(3) {
      background: #ffd1cd;
    }
  }
}
.passenger_footer_left {
  width: 740px;
  .passenger_footer_head {
    height: 72px;
    padding: 0 60px;
    background: #f5f5f5;
    border-radius: 4px 4px 0px 0px;
    li {
      height: 100%;
      line-height: 72px;
      font-size: 22px;
      cursor: pointer;
      &:first-child {
        margin-right: 92px;
      }
    }
    .active {
      color: #3273f6;
      font-weight: bold;
      position: relative;
      &::before {
        position: absolute;
        content: "";
        width: 24px;
        height: 2px;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        background: #3273f6;
      }
    }
  }
}
.passenger_footer_list1 {
  padding: 0 20px;
  li {
    padding: 20px 10px;
    border-bottom: 1px solid #ebebeb;
    &:last-child {
      border-bottom: none;
    }
    .passenger_footer_list1_ul {
      display: flex;
      flex-wrap: wrap;
    }
    .passenger_footer_list1_ul_li {
      width: 50%;
      margin-bottom: 15px;
      &:last-child {
        width: 100%;
        margin-bottom: 0;
      }
      span {
        font-size: 16px;
        &:first-child {
          color: #999999;
        }
      }
    }
    .passenger_footer_list1_ul_lis {
      width: 100%;
    }
  }
}
.passenger_footer_right {
  width: 420px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  .passenger_footer_right_head {
    padding: 30px 0;
    span {
      padding-left: 20px;
      position: relative;
      &::before {
        position: absolute;
        width: 4px;
        height: 20px;
        content: "";
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: #3273f6;
      }
    }
  }
  .passenger_footer_right_ul_img {
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
  .passenger_footer_right_ul_name {
    font-size: 16px;
  }
  .passenger_footer_right_ul_time {
    color: #999999;
    font-size: 12px;
  }
}
.passenger_footer {
  justify-content: space-between;
}
.passenger_footer_right_ul {
  padding-bottom: 1px;
  li {
    padding: 0 20px;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 20px;
    }
    .passenger_footer_right_ul_img {
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }
    .passenger_footer_right_ul_name {
      font-size: 16px;
    }
    .passenger_footer_right_ul_time {
      color: #999999;
    }
    .passenger_footer_right_ul_txt {
      color: #666666;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>
