// 借钥匙
<template>
  <div class="examRule">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="600px"
    >
      <div class="examRule_head flex">
        <span>删除客源</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="examRule_main p-t-30 p-r-40 p-b-30 p-l-40">
        <div class="borrow-form">
          <el-form
            ref="form"
            :rules="rules"
            class="form"
            :model="form"
            label-width="100px"
          >
          <el-form-item label="" label-width="0" prop="remark">
            <div class="audit-text">
              <el-input
                type="textarea"
                :rows="5"
                placeholder="请填写删除原因"
                v-model="form.remark"
              >
              </el-input>
            </div>
            </el-form-item>
          </el-form>
          <div class="interview_main_footer center">
            <el-button @click="submit('form')">提交</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        id:'',
        remark:''
      },
      rules:{
        remark:[
          { required: true, message: '请填写删除原因', trigger: 'blur' },
        ],
      }
    };
  },
  methods: {
    submit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.delClient(this.form).then(res=>{
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.$emit('succend')
            this.dialogVisible = false;
          })
        } else {
          return false;
        }
      });
    },
    //关闭
    close() {
      this.dialogVisible = false;
    },
    //打开
    open(id) {
      this.form.id = id
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
  /deep/ .el-input__inner {
    border: 1px solid #ccc;
    border-radius: 0;
  }
}
/deep/ .el-dialog {
  border-radius: 8px;
  overflow: hidden;
}
.interview_main_footer {
  padding: 30px 0 40px 0;
  /deep/ .el-button {
    width: 480px;
    height: 56px;
    color: #fff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;
  }
}
.audit-text {
  /deep/ .el-textarea__inner {
    border: 1px solid #ccc;
    border-radius: 0;
  }
}
</style>
