// 借钥匙
<template>
  <div class="examRule">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="600px"
    >
      <div class="examRule_head flex">
        <span>{{ type == 1 ? "带看" : "约看" }}反馈</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="examRule_main p-t-30 p-r-40 p-b-30 p-l-40">
        <el-form
          :model="form"
          :rules="rules"
          ref="ruleForm"
          label-width="85px"
          class="demo-ruleForm"
          label-position="left"
        >
          <ul class="examRule_ul">
            <li v-for="(item, index) in content" :key="index">
              <div class="examRule_ul_con flexs">
                <div class="examRule_ul_name">小区名称:</div>
                <div class="examRule_ul_right flex">
                  <el-select
                    @change="changePlot($event,index)"
                    v-model="item.village_name"
                    filterable
                    remote
                    :remote-method="remoteMethod"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in plotList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                  <img
                    @click="addplot"
                    v-if="index == 0"
                    src="../assets/image/houseing/add.png"
                    alt=""
                  />
                  <img
                    @click="delPlot(index)"
                    src="../assets/image/personage/dele.png"
                    v-else
                    alt=""
                  />
                </div>
              </div>
              <div class="examRule_ul_con flexs" v-if="item.village_name">
                <div class="examRule_ul_name">楼栋信息:</div>
                <div class="examRule_ul_right flexs">
                  <el-select
                    v-model="item.house_info"
                    filterable
                    remote
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in louDongList"
                      :key="item.id"
                      :label="item.xiaoqu_loudong"
                      :value="item.xiaoqu_loudong"
                    >
                    </el-option>
                  </el-select>
                  <!-- <div class="examRule_ul_ipt flexs">
                    <el-input v-model="item.lou" placeholder="楼号"></el-input>
                    <span></span>
                    <el-input v-model="item.dan" placeholder="单元"></el-input>
                    <span></span>
                    <el-input
                      v-model="item.hao"
                      placeholder="门牌号"
                    ></el-input>
                  </div> -->
                </div>
              </div>
              <div class="examRule_ul_con flexs">
                <div class="examRule_ul_name">小区地址:</div>
                <div class="examRule_ul_right">
                  <el-input
                    disabled
                    v-model="item.village_address"
                    placeholder="请输入小区地址"
                  ></el-input>
                </div>
              </div>
            </li>
          </ul>
          <ul class="examRule_footer">
            <li>
              <el-form-item :label="`${ type == 1 ? '带看' : '约看' }日期:`" prop="date_time">
                <el-date-picker
                  v-model="form.date_time"
                  type="datetime"
                  :clearable="false"
                  placeholder="选择日期"
                  format="yyyy-MM-dd HH:mm"
                  value-format="yyyy-MM-dd HH:mm"
                >
                </el-date-picker>
              </el-form-item>
            </li>
            <li>
              <el-form-item label="反馈意见:" prop="remark">
                <el-input
                  type="textarea"
                  :rows="5"
                  placeholder="请输入内容"
                  v-model="form.remark"
                >
                </el-input>
              </el-form-item>
            </li>
          </ul>
        </el-form>
        <div class="examRule_btn center">
          <el-button @click="submit('ruleForm')">提交</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pickerOptions:{
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      type: 1, //1带看2越看
      dialogVisible: false,
      form: {
        ref_id: "",
        date_time: "",
        remark: "",
        content: "",
      },
      content: [
        {
          village_name: "",
          house_info:'',
          village_address: ''
        },
      ],
      rules: {
        remark: [
          { required: true, message: "请输入反馈意见", trigger: "blur" },
        ],
        date_time: [
          { required: true, message: "请选择带看日期", trigger: "change" },
        ],
      },
      userList: [], //客户列表
      plotList: [],
      louDongList:[],//楼栋列表
    };
  },
  methods: {
    getTodayDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hour = date.getHours().toString().padStart(2, '0'); // 时
      const minutes = date.getMinutes().toString().padStart(2, '0'); // 分
      const seconds = date.getSeconds().toString().padStart(2, '0') //秒
      return [year, month, day].join('-') + ' ' + hour + ':' + minutes
    },
    changePlot(e,index) {
      this.plotList.forEach(item=>{
        if (e == item.name) {
          this.getnewLouDong(e)
          this.content[index].village_address = item.address
        }
      })
    },
    getnewLouDong (name) {
      this.$axios.newLouDong({name,status:1}).then(res=>{
        
        this.louDongList = res.data
      })
    },
    remoteMethod(e) {
      this.$axios
        .xiaoQuList({
          letter: e,
          pageNum: 10000,
        })
        .then((res) => {
          this.plotList = res.data.data;
        });
    },
    delPlot(index) {
      this.content.splice(index, 1);
    },
    addplot() {
      this.content.push({
        village_name: "",
        house_info:'',
        village_address: ""
      });
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          
          this.form.content = JSON.stringify(this.content);
          this.$axios[this.type == 1 ? "addDk" : "addYk"](this.form).then(
            (res) => {
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.$emit("succeed");
              this.dialogVisible = false;
              this.content = [
                {
                  village_name: "",
                  lou: "",
                  dan: "",
                  hao: "",
                  village_address: "",
                },
              ];
              this.form.remark = "";
              this.form.content = "";
            }
          );
        } else {
          return false;
        }
      });
    },
    //关闭
    close() {
      this.dialogVisible = false;
    },
    //打开
    open(id, type) {
      this.type = type;
      this.form.ref_id = id;
      this.dialogVisible = true;
      this.form.date_time = this.getTodayDate();
      this.$axios.notTradingClientList().then((res) => {
        this.userList = res.data;
      });
      this.remoteMethod('')
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
  /deep/ .el-input__inner {
    border: 1px solid #ccc;
    border-radius: 0;
  }
}
/deep/ .el-dialog {
  border-radius: 8px;
  overflow: hidden;
}
.audit-text {
  /deep/ .el-textarea__inner {
    border: 1px solid #ccc;
    border-radius: 0;
  }
}
/deep/ .el-input__inner {
  width: 380px;
}
.examRule_ul {
  li {
    background: #f5f5f5;
    padding: 20px 15px;
    margin-bottom: 20px;
    .examRule_ul_con {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    img {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
    span {
      width: 20px;
      height: 1px;
      margin: 0 10px;
      display: block;
      border: 1px solid #cccccc;
    }
    .examRule_ul_right {
      flex: 1;
    }
    .examRule_ul_ipt {
      /deep/ .el-input__inner {
        width: 100px;
      }
    }
  }
}
.examRule_ul_name {
  margin-right: 20px;
}
.examRule_footer {
  li {
    padding: 0 20px;
    align-items: center;
    /deep/ .el-input,
    /deep/ .el-input__inner,
    /deep/ .el-form-item {
      width: 100%;
    }
  }
}
.examRule_btn {
  /deep/ .el-button {
    width: 480px;
    height: 56px;
    color: #ffffff;
    text-align: center;
    background: #3273f6;
    border-radius: 28px;
  }
}
</style>
