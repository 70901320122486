<template>
  <div class="">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="600px"
    >
    <div class="alteration flex">
      <div class="alteration_txt">是否变为{{ type == 1 ? '公客' : '私客' }}</div>
      <div class="alteration_footer flex">
        <el-button @click="close">取消</el-button>
        <el-button @click="submit">确认</el-button>
      </div>
    </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      id:null,//
      type:1,//公私客类型：1 私客；2 公客,
      dialogVisible:false
    }
  },
  methods:{
    submit () {
      this.$axios.changeClient({id:this.id,type:this.type == 1 ? 2 : 1}).then(res=>{
        this.$message({
          message: res.msg,
          type: 'success'
        });
        this.close()
        this.$emit('succeed')
      })
    },
    close () {
      this.dialogVisible = false
    },
    open (id,type) {
      this.id = id
      this.type = type
      this.dialogVisible = true
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0;
}
.alteration {
  width: 600px;
  height: 252px;
  padding: 60px 80px 50px 80px;
  align-items: center;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 8px;
  .alteration_txt {
    font-size: 22px;
  }
  .alteration_footer {
    width: 100%;
    /deep/ .el-button {
      width: 200px;
      height: 56px;
      padding: 0;
      line-height: 56px;
      color: #FFFFFF;
      font-size: 18px;
      text-align: center;
      line-height: 56px;
      background: #CCCCCC;
      &:last-child {
        background: #3273F6;
      }
    }
  }
}
</style>