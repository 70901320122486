<template>
  <div class="">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="600px"
    >
      <div class="alteration flex">
        <div class="alteration_txt">是否变更客源</div>
        <div class="alteration_footer flex">
          <el-button @click="submit(0)">{{ type == 1 ? "公客" : "私客" }}</el-button>
          <el-button @click="submit(1)">他人</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: 1, //公私客类型：1 私客；2 公客,
      dialogVisible: false,
    };
  },
  methods: {
    submit(index) {
      this.dialogVisible = false;
      this.$emit("succeed",index);
    },
    open(type) {
      this.type = type;
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0;
}
.alteration {
  width: 600px;
  height: 252px;
  padding: 60px 80px 50px 80px;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
  border-radius: 8px;
  .alteration_txt {
    font-size: 22px;
  }
  .alteration_footer {
    width: 100%;
    /deep/ .el-button {
      width: 200px;
      height: 56px;
      padding: 0;
      line-height: 56px;
      color: #ffffff;
      font-size: 18px;
      text-align: center;
      line-height: 56px;
      background: #3273f6;
    }
  }
}
</style>
