


<template>
  <div class="">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="600px"
    >
    <div class="alteration flex">
      <div class="alteration_txt">
        <el-select v-model="ruleForm.user_id" placeholder="请选择">
          <el-option
            v-for="item in userLists"
            :key="item.user_id"
            :label="item.name"
            :value="item.user_id">
          </el-option>
        </el-select>
      </div>
      <div class="alteration_footer flex">
        <el-button @click="close">取消</el-button>
        <el-button @click="submit">确认</el-button>
      </div>
    </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      id:null,//
      type:1,//公私客类型：1 私客；2 公客,
      dialogVisible:false,
      ruleForm:{
        id:null,
        user_id:null
      },
      userLists:[]
    }
  },
  methods:{
    submit () {
     if (!this.ruleForm.user_id) return this.$message({
          message: '请选择要变更的人',
          type: 'warning'
        });
      this.$axios.bianClinet(this.ruleForm).then(res=>{
        this.$message({
          message: res.msg,
          type: 'success'
        });
        this.close()
        this.$emit('succeed')
      })
    },
    close () {
      this.dialogVisible = false
    },
    open (id) {
      
      this.userList()
      this.ruleForm.id = id
      this.dialogVisible = true
    },
    userList () {
      this.$axios.userList({
        position:4
      }).then(res=>{
        this.userLists = res.data
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0;
}
.alteration {
  width: 600px;
  height: 252px;
  padding: 60px 80px 50px 80px;
  align-items: center;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 8px;
  .alteration_txt {
    font-size: 22px;
  }
  .alteration_footer {
    width: 100%;
    /deep/ .el-button {
      width: 200px;
      height: 56px;
      padding: 0;
      line-height: 56px;
      color: #FFFFFF;
      font-size: 18px;
      text-align: center;
      line-height: 56px;
      background: #CCCCCC;
      &:last-child {
        background: #3273F6;
      }
    }
  }
}
</style>