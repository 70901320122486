<template>
  <div class="examRule">
    <el-dialog
      title=""
      v-dialogDrag
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="600px"
    >
      <div class="examRule_head flex">
        <span>跟进记录</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="p-l-20 p-r-20 p-t-20 p-b-20 view-pop">
        <!-- <div class="f-24 color-3 m-t-20 m-b-40">楼栋号：1号楼1单元1201室东</div> -->
        <div class="view-text">
          <el-input v-model="from.remark" type="textarea" placeholder="请描述您的跟进记录" rows="5"></el-input>
        </div>
        <div class="interview_main_footer center">
          <el-button @click="submit('form')">提交</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible:false,
      from:{
        ref_id:'',
        look_id:'',
        remark:''
      },
      type:1
    }
  },
  methods:{
    submit () {
      if (!this.from.remark) return this.$message.error('请描述您的跟进记录');
      this.$axios.addGn(this.from).then(res=>{
        this.$message({
          message: res.msg,
          type: 'success'
        });
        this.dialogVisible = false
        this.from.remark = ''
        this.$emit('succeed',this.type)
      })
    },
    //关闭
    close () {
      this.dialogVisible = false
    },
    //打开
    open (id,look_id) {
      this.from.ref_id = id
      this.from.look_id = look_id
      this.dialogVisible = true
    }
  }
}
</script>

<style lang="less" scoped>
// /deep/ .el-dialog__header ,
/deep/ .el-dialog__body {
  padding: 0 !important;
}
/deep/ .el-dialog{border-radius:8px;overflow:hidden}

// .examRule_main {
//   // line-height: 28px;
// }
.f-24{font-size:24px;}
.view-pop{
  .view-text{background:#F5F5F5;border-radius:8px;padding:10px;}
  /deep/ .view-text .el-textarea__inner{border:none;outline:none;background:none;font-size:18px;}
}
.interview_main_footer {
  padding: 40px 0 40px 0;
  /deep/ .el-button {
    width: 480px;
    height: 56px;
    color: #fff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;
  }
}
</style>
